import React, { useState, useEffect } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';

const Slider = ({ slides, interval = 5000 }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, interval);

    return () => {
      clearInterval(slideInterval);
    };
  }, [slides.length, interval]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className={classes.slider}>
      <Button icon className={classes.leftArrow} onClick={goToPreviousSlide}>
        <Icon name="chevron left" />
      </Button>
      <div className={classes.slide}>
        <Image src={slides[currentIndex].image} fluid />
        <div className={classes.caption}>{slides[currentIndex].caption}</div>
      </div>
      <Button icon className={classes.rightArrow} onClick={goToNextSlide}>
        <Icon name="chevron right" />
      </Button>
    </div>
  );
};

export default Slider;


const useStyles = createUseStyles({
  slider: {
    position: 'relative',
    width: '99.9%',
    height: '90vh',
    margin: '0 auto',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px'
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: '3px'
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff !important',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1) !important',
    zIndex: 1,
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff !important',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1) !important',
    zIndex: 1,
  },
  caption: {
    position: 'absolute',
    marginTop: '10px',
    fontSize: '1.2em',
    fontStyle: 'oblique',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF !important',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1) !important',
    color: '#000000',
    zIndex: 1,
    padding: '.5em 1em',
    top: '10px',
    left: '0px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    opacity: '.7'
  },
});






