import React from 'react';
import { Container, Header, Segment, Grid, Image, List } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import pssharma from '../images/founder/pssharma.jpg'

const AboutPage = () => {
    const classes = useStyles();
    const company = useSelector((state) => state.company);

    return (
        <Container>
            {/* Welcome Section */}
            <Segment className={classes.segment} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Image src={company.about.welcome.image} className={classes.sectionImage} />
                            <Header as='h2' className={classes.header}>
                                Welcome to {company.name}.
                            </Header>
                            <p className={classes.text}>
                                {company.about.welcome.message}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Our History Section */}
            <Segment className={classes.segment} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Image src={company.about.history.image} className={classes.sectionImage} />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header as='h2' className={classes.header}>Our History</Header>
                            <p className={classes.text}>
                                {company.about.history.message}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Our Services Section */}
            <Segment className={classes.segment} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Header as='h2' className={classes.header}>Our Services</Header>
                            <p className={classes.text}>
                                {company.about.service.message}
                            </p>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Image src={company.about.service.image} className={classes.sectionImage} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Our Strengths Section */}
            <Segment className={classes.segment} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Header as='h2' className={classes.header}>Our Strengths</Header>
                            <List className={classes.list}>
                                {company.about.strength.items.map(item => (
                                    <List.Item key={item.title} className={classes.listItem}>
                                        {item.title}: {item.description}
                                    </List.Item>
                                ))}
                            </List>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Image src={company.about.strength.image} className={classes.sectionImage} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Our Reach Section */}
            <Segment className={classes.segment} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Image src={company.about.presence.image} className={classes.sectionImage} />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header as='h2' className={classes.header}>Our Presence</Header>
                            <p className={classes.text}>
                                {company.about.presence.message}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Mission and Vision Section */}
            <Segment className={classes.missionVisionSection} vertical>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as='h2' className={classes.header}>Our Mission</Header>
                            <p className={classes.missionVisionText}>
                                {company.about.mission.message}
                            </p>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as='h2' className={classes.header}>Our Vision</Header>
                            <p className={classes.missionVisionText}>
                                {company.about.vision.message}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {/* Founder Section */}
            <Segment className={classes.founderSection} vertical>
                <Header as='h2' className={classes.header} textAlign='center'>
                    Meet Our Founder
                </Header>
                <Grid container stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Image src={pssharma} className={classes.founderImage} />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header as='h3' className={classes.header}>{company.about.founder.name}</Header>
                            <p className={classes.text}>
                                {company.about.founder.message}
                            </p>
                            <Header as='h3' className={classes.futureVision}>Future Vision</Header>
                            <p className={classes.text}>
                                {company.about.founder.vision}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    );
};

export default AboutPage;

const useStyles = createUseStyles({
    header: {
        marginBottom: '1em',
        fontFamily: 'Montserrat, sans-serif',
        color: '#2c3e50',
        fontWeight: 'bold',
    },
    segment: {
        padding: '4em 2em',
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        marginBottom: '2em',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
            boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
            transform: 'scale(1.02)',
        },
    },
    sectionImage: {
        width: '100%',
        borderRadius: '12px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        marginBottom: '2em',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
    },
    text: {
        fontSize: '1.1em',
        color: '#2c3e50',
        lineHeight: '1.6em',
    },
    founderImage: {
        borderRadius: '50%',
        width: '200px',
        height: '200px',
        border: '2px solid #2c3e50',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        margin: '2em auto',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        },
    },
    list: {
        marginTop: '1.5em',
        fontFamily: 'Montserrat, sans-serif',
        padding: '0 1em',
    },
    listItem: {
        marginBottom: '1em',
        fontSize: '1.1em',
        lineHeight: '1.5em',
        transition: 'color 0.3s ease',
        '&:hover': {
            color: '#3498db',
        },
    },
    futureVision: {
        marginTop: '2em',
        fontWeight: 'bold',
        color: '#3498db',
    },
    missionVisionSection: {
        backgroundColor: '#ffffff',
        padding: '4em 2em',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        marginBottom: '2em',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
        },
    },
    missionVisionText: {
        marginTop: '1em',
        fontSize: '1.1em',
        fontFamily: 'Montserrat, sans-serif',
        color: '#7f8c8d',
        lineHeight: '1.6em',
    },
});
