import React from 'react';
import { Container, Header, Segment, Grid, Card, Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';



const ClientsPage = () => {
  const classes = useStyles();
  const clients = useSelector((state) => state.company.clients);

  return (
    <Segment vertical className={classes.segment}>
      <Container>
        <Header as='h2' textAlign='center' className={classes.header}>
          Our Esteemed Clients
        </Header>
        <Grid stackable columns={4} centered>
          {clients.map((client, index) => (
            <Grid.Column key={index} className={classes.gridColumn}>
              <Card centered raised className={classes.card}>
                <Image src={client.logo} wrapped ui={false} className={classes.image} />
                <Card.Content textAlign='center' className={classes.cardContent}>
                  <Card.Header className={classes.cardHeader}>
                    {client.name}
                  </Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      </Container>
    </Segment>
  );
};

export default ClientsPage;

const useStyles = createUseStyles({
  segment: {
    padding: '5em 0em',
    background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
    borderTop: '3px solid #ddd',
  },
  header: {
    marginBottom: '3em',
    fontSize: '2.8em',
    color: '#222',
    fontFamily: 'Roboto, Arial, sans-serif',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
  },
  gridColumn: {
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '100%',
    maxWidth: '280px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
      transform: 'scale(1.05)',
    },
  },
  image: {
    height: '150px',
    objectFit: 'contain',
    padding: '1em',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #e0e0e0',
  },
  cardContent: {
    padding: '1.5em',
    backgroundColor: '#ffffff',
  },
  cardHeader: {
    fontSize: '1.4em',
    fontWeight: '600',
    color: '#333',
    fontFamily: 'Roboto, Arial, sans-serif',
    marginTop: '0.5em',
  },
});
