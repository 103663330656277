import React from 'react';
import { Container, Header, Segment, Grid, Card } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const StaffsPage = () => {
  const classes = useStyles();
  const staffs = useSelector((state) => state.company.staffs);

  return (
    <Segment vertical className={classes.page}>
      <Container>
        <Header as='h2' textAlign='center' className={classes.header}>
          Meet Our Team
        </Header>
        <Grid stackable columns={3} centered stretched>
          {staffs.map((member, index) => (
            <Grid.Column key={index} mobile={16} tablet={8} computer={5}>
              <Card className={classes.card} centered raised>
                <div className={classes.cardInner}>
                  <div
                    className={classes.cardFront}
                    style={{ backgroundImage: `url(${member.photo})` }}
                  >
                    <div className={classes.cardOverlay}>
                      <div className={classes.cardHeader}>
                        {member.name}
                      </div>
                      <div className={classes.cardMeta}>
                        {member.role}
                      </div>
                    </div>
                  </div>
                  <div className={classes.cardBack}>
                    <Card.Content>
                      <Card.Description className={classes.cardDescription}>
                        {member.description}
                      </Card.Description>
                    </Card.Content>
                  </div>
                </div>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      </Container>
    </Segment>
  );
};

export default StaffsPage;

const useStyles = createUseStyles({
  page: {
    padding: '5em 0em',
    background: '#f4f4f4',
    borderTop: '1px solid #ddd',
  },
  header: {
    marginBottom: '4em',
    fontSize: '3em',
    fontWeight: 700,
    color: '#333',
    fontFamily: 'Roboto, sans-serif',
  },
  card: {
    overflow: 'hidden',
    position: 'relative',
    width: '300px',
    height: '400px',
    perspective: '1000px', // Ensure perspective is set on the parent container
    borderRadius: '3px', // Apply border-radius to the card itself
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  cardInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    '&:hover': {
      transform: 'rotateY(180deg)',
    },
  },
  cardFront: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    borderRadius: '3px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
  cardOverlay: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '1em',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    textAlign: 'center',
    borderRadius: '0 0 3px 3px',
    boxShadow: '0 -6px 12px rgba(0, 0, 0, 0.15)',
  },
  cardHeader: {
    fontSize: '1.6em',
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: 'Roboto, sans-serif',
  },
  cardMeta: {
    fontSize: '1.2em',
    color: '#ddd',
    fontFamily: 'Roboto, sans-serif',
    marginTop: '0.5em',
  },
  cardBack: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    backgroundColor: '#f4f4f4',
    transform: 'rotateY(180deg)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',
    borderRadius: '3px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    zIndex: 0,
  },
  cardDescription: {
    marginTop: '1em',
    fontSize: '1em',
    color: '#555',
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '1.6',
  },
});
