import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    background: 'radial-gradient(circle, #444444 16%,  #777777 80%)',
}

const styleSlice = createSlice({
    name: 'customStyles',
    initialState,
    reducers: {
        changeBackground: (state, action) => {
            state.customStyles.background = action.payload;
        },
        removeService: (state, action) => {
            state.customStyles.background = state.customStyles.background.filter(service => service.id !== action.payload);
        },
    },
});

export const { changeBackground, removeService } = styleSlice.actions;

export default styleSlice.reducer;