import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/App.css'

import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import ClientsPage from './pages/ClientsPage';
import GalleryPage from './pages/GalleryPage';
import ServicesPage from './pages/ServicesPage';
import StaffsPage from './pages/StaffsPage';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import AboutPage from './pages/AboutPage';

function App() {
  return (
    <BrowserRouter >
      <Header>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/staffs" element={<StaffsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </Header>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
