import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Container,
    Icon,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { createMedia } from '@artsy/fresnel';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});

const Desktop = ({ children }) => {
    const customStyles = useSelector((state) => state.customStyles);
    const [fixed, setFixed] = useState(false);
    const toggleFixedMenu = (inView) => setFixed(!inView);

    return (
        <Media greaterThan='mobile'>
            <InView onChange={toggleFixedMenu}>
                <Menu
                    fixed={fixed ? 'top' : null}
                    inverted={!fixed}
                    pointing={!fixed}
                    secondary={!fixed}
                    size='large'
                    style={{
                        backgroundImage: customStyles.background || 'linear-gradient(to right, #333, #555)',
                        minHeight: '60px',
                        paddingBottom: '.5em',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.3)',
                        borderBottom: '1px solid #444',
                    }}
                >
                    <Menu.Item style={{ color: '#f1f1f1' }}>
                        <Icon name='shield' />
                        SAVIOUR
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        {['Home', 'About Us', 'Services', 'Clients', 'Staffs', 'Gallery'].map((item) => (
                            <Menu.Item
                                key={item}
                                as={NavLink}
                                to={`/${item.toLowerCase().replace(' ', '-')}`}
                                style={{
                                    color: '#e0e0e0',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    margin: '0 1em',
                                    transition: 'color 0.3s',
                                }}
                                activeStyle={{ color: '#ffffff' }}
                            >
                                {item}
                            </Menu.Item>
                        ))}
                    </Menu.Menu>
                </Menu>
            </InView>
            {children}
        </Media>
    );
};

const Mobile = ({ children }) => {
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const handleSidebarHide = () => setSidebarOpened(false);
    const handleToggle = () => setSidebarOpened(true);

    return (
        <Media as={Sidebar.Pushable} at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                    style={{
                        backgroundColor: '#333',
                        color: '#e0e0e0',
                    }}
                >
                    <Menu.Item style={{ color: '#f1f1f1' }}>
                        <Icon name='shield' />
                        SAVIOUR
                    </Menu.Item>
                    {['Home', 'About Us', 'Services', 'Clients', 'Staffs', 'Gallery'].map((item) => (
                        <Menu.Item
                            key={item}
                            as={NavLink}
                            to={`/${item.toLowerCase().replace(' ', '-')}`}
                            style={{
                                color: '#e0e0e0',
                                fontSize: '18px',
                                fontWeight: '500',
                                margin: '1em 0',
                            }}
                            activeStyle={{ color: '#ffffff' }}
                        >
                            {item}
                        </Menu.Item>
                    ))}
                </Sidebar>
                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment inverted textAlign='center' vertical>
                        <Container>
                            <Menu inverted pointing size='large'>
                                <Menu.Item onClick={handleToggle}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    );
};

const Header = ({ children }) => (
    <MediaContextProvider>
        <Desktop>{children}</Desktop>
        <Mobile>{children}</Mobile>
    </MediaContextProvider>
);

Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
