import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Icon,
  Form,
  Container
} from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';

import Slider from '../components/Slider';
import Service from '../components/Service';
import Staff from '../components/Staff';

const HomePage = () => {
  const customStyles = useSelector((state) => state.customStyles);
  const classes = useStyles({ customStyles });
  const slides = useSelector((state) => state.company.slides);
  const services = useSelector((state) => state.company.services);
  const clients = useSelector((state) => state.company.clients);
  const staffs = useSelector((state) => state.company.staffs);

  const clientScrollRef = useRef(null);
  const staffScrollRef = useRef(null);

  const sectionRefs = {
    services: useRef(null),
    team: useRef(null),
    clients: useRef(null),
    contact: useRef(null),
  };

  // const scrollToSection = (section) => {
  //   sectionRefs[section].current?.scrollIntoView({ behavior: 'smooth' });
  // };

  const staffScrollLeft = () => {
    staffScrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const staffScrollRight = () => {
    staffScrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (clientScrollRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = clientScrollRef.current;
        if (scrollLeft + clientWidth >= scrollWidth) {
          clientScrollRef.current.scrollLeft = 0;
        } else {
          clientScrollRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (staffScrollRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = staffScrollRef.current;
        if (scrollLeft + clientWidth >= scrollWidth) {
          staffScrollRef.current.scrollLeft = 0;
        } else {
          staffScrollRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');
    const mailtoLink = `mailto:info@saviourgroup.com?subject=Contact%20Us&body=Name:%20${name}%0D%0AEmail:%20${email}%0D%0AMessage:%20${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className={classes.page}>
      <Slider slides={slides} />
      {/* <section className={classes.nav}>
          <Button onClick={() => scrollToSection('services')} className={classes.navButton}>Our Services</Button>
          <Button onClick={() => scrollToSection('team')} className={classes.navButton}>Our Team</Button>
          <Button onClick={() => scrollToSection('clients')} className={classes.navButton}>Our Clients</Button>
          <Button onClick={() => scrollToSection('contact')} className={classes.navButton}>Get in Touch</Button>
        </section> */}

      <section ref={sectionRefs.services} className={classes.section}>


        <h2 className={classes.title}>Our Services</h2>
        <Container>
          <Grid stackable columns={3} centered>
            {services.map((service, index) => <Service key={index} service={service} />)}
          </Grid>
        </Container>
      </section>

      <section ref={sectionRefs.team} className={classes.section}>
        <h2 className={classes.title}>Our Team</h2>
        <div className={classes.scrollX}>
          <Button icon onClick={staffScrollLeft} className={classes.scrollXButton}>
            <Icon name='left arrow' />
          </Button>
          <div className={classes.scrollXContent} ref={staffScrollRef}>
            {staffs.map((member, index) => (
              <Staff
                key={index}
                name={member.name}
                role={member.role}
                photo={member.photo}
                description={member.description}
              />
            ))}
          </div>
          <Button icon onClick={staffScrollRight} className={classes.scrollXButton}>
            <Icon name='right arrow' />
          </Button>
        </div>
      </section>

      <section ref={sectionRefs.clients} className={classes.section}>
        <h2 className={classes.title}>Our Clients</h2>
        <div className={classes.clientsGrid}>
          {clients.map(client => (
            <div key={client.id} className={classes.clientItem}>
              <img src={client.logo} alt={client.name} className={classes.clientLogo} />
              <p className={classes.clientName}>{client.name}</p>
            </div>
          ))}
        </div>
        <div className={classes.clientsLink}>
          <a href="/clients">See all our clients</a>
        </div>
      </section>

      <section ref={sectionRefs.contact} className={classes.section}>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column computer={6} tablet={8} mobile={16}>
                <h2 className={classes.title}>Get in Touch</h2>
                <p>
                  If you have any questions or inquiries, please fill out the form below and we'll get back to you as soon as possible.
                </p>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <div className={classes.form}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Input label='Name' name='name' placeholder='Your Name' required />
                    <Form.Input label='Email' name='email' placeholder='Your Email' type='email' required />
                    <Form.TextArea label='Message' name='message' placeholder='Your Message' required />
                    <Button type='submit' className={classes.button}>Submit</Button>
                  </Form>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </section>

    </div>
  );
};

export default HomePage;

const useStyles = createUseStyles({
  page: {
    marginTop: '.2em',
    background: '#CCCCCC',
    paddingBottom: '1em',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '60px 30px',
    background: "radial-gradient(circle, #FFFFFF, #EEEEEE)",
    margin: '1em 0.5em',
    borderRadius: "0.5em",
  },
  scrollX: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    padding: '1em 0',
  },
  scrollXContent: {
    display: 'flex',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollXButton: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#fff !important',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1) !important',
    '&:first-child': {
      left: '0.5em',
    },
    '&:last-child': {
      right: '0.5em',
    },
  },
  title: {
    fontSize: '2.2rem',
    marginBottom: '50px',
    fontWeight: '700',
    color: '#333',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  },
  clientsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    gap: '40px',
    alignItems: 'center',
    justifyItems: 'center',
  },
  clientItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: '0 10px 25px rgba(0, 0, 0, 0.15)',
    },
  },
  clientLogo: {
    width: '160px',
    height: '90px',
    marginBottom: '15px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, filter 0.3s ease',
    filter: 'grayscale(100%)',
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'grayscale(0%)',
    },
  },
  clientName: {
    fontSize: '.8rem',
    fontWeight: '600',
    color: '#555',
    textAlign: 'center',
  },
  clientsLink: {
    marginTop: '50px',
    textAlign: 'center',
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '10px 20px',
      textDecoration: 'none',
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: '600',
      borderRadius: '8px',
      background: 'linear-gradient(45deg, #6B8E23, #556B2F)', // Olive green gradient
      transition: 'background 0.3s ease, color 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: 'linear-gradient(45deg, #556B2F, #4B5320)', // Darker olive green gradient
        color: '#e0e0e0',
      },
    },
    '& a .icon': {
      marginLeft: '8px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    textAlign: 'left',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  },
  button: {
    background: 'linear-gradient(45deg, #6B8E23, #556B2F)!important', // Light olive gradient
    color: '#fff !important',
    border: 'none !important',
    padding: '10px 20px !important',
    textAlign: 'center !important',
    textDecoration: 'none !important',
    display: 'inline-block !important',
    fontSize: '16px !important',
    margin: '4px 2px !important',
    cursor: 'pointer !important',
    borderRadius: '4px !important',
    transition: 'background 0.3s ease !important',
    '&:hover': {
      background: 'linear-gradient(45deg, #556B2F, #4B5320)!important', // Slightly darker on hover
    },
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    backgroundColor: '#333',
  },
  navButton: {
    margin: '0 10px',
    color: '#fff',
    backgroundColor: '#555',
    '&:hover': {
      backgroundColor: '#777',
    },
  },
});
