import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: "Saviour Intelligent Guard Force Pvt. Ltd.",
  address: {
    street: 'G-4/5, Doctor House, Raheja Complex, Near Patripool',
    city: 'Kalyan West',
    state: 'Maharashtra',
    country: 'India',
    postal_code: '421301',
  },
  contact: {
    email: 'info@saviourgroup.com',
    phone_number: '+91 9322816829'
  },
  slides: [
    { image: 'https://images.squarespace-cdn.com/content/v1/5f57607a3e5b891978a992b9/1663915253943-EXO2FFWC3ORPOMTTNTJV/cover-security-guard.jpg', caption: 'Professional Security Services' },
    { image: 'https://armourguard.co.nz/wp-content/uploads/2023/06/DSC01075-Web-size-42.jpg', caption: 'Advanced Surveillance Systems' },
    { image: 'https://www.labourbeat.org/wp-content/uploads/2023/08/Security-1280-scaled.jpg', caption: 'Your Safety, Our Priority' },
  ],
  services: [
    {
      icon: 'shield',
      title: 'Manned Guarding',
      description: 'Professional and highly trained security personnel to protect your assets and premises.',
      imageUrl: 'https://3.imimg.com/data3/FF/FR/MY-8130806/security-and-manned-guarding-services-500x500.jpg'
    },
    {
      icon: 'camera',
      title: 'CCTV Monitoring',
      description: '24/7 surveillance services to ensure constant monitoring and prompt response to incidents.',
      imageUrl: 'https://safeguardsystems.co.uk/wp-content/uploads/2021/06/CCTV-Monitoring-scaled-e1624549729110-1110x739.jpeg'
    },
    {
      icon: 'car',
      title: 'Mobile Patrols',
      description: 'Regular patrols by our security officers to deter criminal activity and provide a visible security presence.',
      imageUrl: 'https://security.safety-international.com/wp-content/uploads/2022/04/Mobile-Security-Patrol.jpg'
    },
    {
      icon: 'lock',
      title: 'Access Control',
      description: 'Advanced access control systems to manage and monitor entry to your property.',
      imageUrl: 'https://www.hoyez.com/wp-content/uploads/2021/07/access-control-card-reader.jpg'
    },
    {
      icon: 'bell',
      title: 'Alarm Response',
      description: 'Rapid response to alarms and incidents, ensuring swift action and resolution.',
      imageUrl: 'https://contractsecurityuk.co.uk/wp-content/uploads/2013/10/alarm-pad.png'
    },
    {
      icon: 'users',
      title: 'Event Security',
      description: 'Comprehensive security solutions for events, ensuring the safety of attendees and the smooth running of your event.',
      imageUrl: 'https://nawakara.com/wp-content/uploads/2023/01/nwkr-20232100-Copy.png'
    },
  ],
  clients: [
    { name: 'LF Logistics', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjIny9acIPcLvIUoVjriaTCi8JFqgf3-UtWg&s' },
    { name: 'Bharat Gears Pvt. Ltd.', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHuBY_-4aWsmeLR5Jg4Fg6F7dBEoT-DN-KkQ&s' },
    { name: 'Abhinav Sahakari Bank Ltd.', logo: 'https://content3.jdmagicbox.com/comp/thane/w4/022pxx22.xx22.130801113228.l7w4/catalogue/the-abhinav-sahakari-bank-ltd-dombivli-west-thane-banks-yovlrp.jpg' },
    { name: 'Company D', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company E', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company F', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company G', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company H', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company I', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company J', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company K', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
    { name: 'Company L', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOSlHlpmfg-ApduS07dBq-o1lDAU1M2VybeA&s' },
  ],
  staffs: [
    {
      name: 'Sujeet Sharma',
      role: 'Chief Security Officer',
      photo: 'https://scontent-pnq1-1.xx.fbcdn.net/v/t39.30808-6/449056534_7707869439299640_3520197653129875487_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=QcSn-pYfahoQ7kNvgEXaBki&_nc_ht=scontent-pnq1-1.xx&oh=00_AYC6n-OUF5g3XJJiSs3R7HUc3j5Wt0p8inkvstAWIc12jg&oe=66B3AF49',
      description: 'John has over 15 years of experience in security management. He oversees our security operations and ensures the highest standards of safety and protection for our clients.',
    },
    {
      name: 'Abhishek Maurya',
      role: 'Security Consultant',
      photo: 'https://scontent-pnq1-1.xx.fbcdn.net/v/t39.30808-6/278071182_4699970643444741_6191950136268441208_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=oaE2tyNoQ-QQ7kNvgHvd3mo&_nc_ht=scontent-pnq1-1.xx&oh=00_AYCSsB3Q8x0HkhkeDlBJjDdExjBrpcL8-U8cSe4F6McOLQ&oe=66B3B118',
      description: 'Jane specializes in risk assessment and security strategy development. She works closely with clients to identify security needs and implement effective solutions.',
    },
    {
      name: 'Mike Johnson',
      role: 'Head of Patrols',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Mike leads our mobile patrol unit, ensuring timely and efficient patrols to prevent security breaches and maintain a visible security presence.',
    },
    {
      name: 'John Doe',
      role: 'Chief Security Officer',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'John has over 15 years of experience in security management. He oversees our security operations and ensures the highest standards of safety and protection for our clients.',
    },
    {
      name: 'Jane Smith',
      role: 'Security Consultant',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Jane specializes in risk assessment and security strategy development. She works closely with clients to identify security needs and implement effective solutions.',
    },
    {
      name: 'Mike Johnson',
      role: 'Head of Patrols',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Mike leads our mobile patrol unit, ensuring timely and efficient patrols to prevent security breaches and maintain a visible security presence.',
    },
    {
      name: 'Jane Smith',
      role: 'Security Consultant',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Jane specializes in risk assessment and security strategy development. She works closely with clients to identify security needs and implement effective solutions.',
    },
    {
      name: 'Mike Johnson',
      role: 'Head of Patrols',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Mike leads our mobile patrol unit, ensuring timely and efficient patrols to prevent security breaches and maintain a visible security presence.',
    },
    {
      name: 'John Doe',
      role: 'Chief Security Officer',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'John has over 15 years of experience in security management. He oversees our security operations and ensures the highest standards of safety and protection for our clients.',
    },
    {
      name: 'Jane Smith',
      role: 'Security Consultant',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Jane specializes in risk assessment and security strategy development. She works closely with clients to identify security needs and implement effective solutions.',
    },
    {
      name: 'Mike Johnson',
      role: 'Head of Patrols',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Mike leads our mobile patrol unit, ensuring timely and efficient patrols to prevent security breaches and maintain a visible security presence.',
    },
    {
      name: 'Jane Smith',
      role: 'Security Consultant',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Jane specializes in risk assessment and security strategy development. She works closely with clients to identify security needs and implement effective solutions.',
    },
    {
      name: 'Mike Johnson',
      role: 'Head of Patrols',
      photo: 'https://img.freepik.com/premium-photo/business-man-arms-crossed-work-portrait-with-career-confidence-smile-studio-happy-expert-male-professional-with-corporate-job-pride-employee-with-white-background-worker_590464-219229.jpg?w=360',
      description: 'Mike leads our mobile patrol unit, ensuring timely and efficient patrols to prevent security breaches and maintain a visible security presence.',
    },

  ],
  about: {
    welcome: {
      message: "At Saviour Intelligent Guard Force Pvt. Ltd., we are dedicated to providing top-notch security services across India. With a legacy of 20 years in the industry, we have established ourselves as a trusted name in safeguarding businesses and individuals alike.",
      image: "https://sp-ao.shortpixel.ai/client/q_lossy,ret_img,w_2016/https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg"
    },
    history: {
      message: "Founded by Mr. P. S. Sharma, a distinguished BHU graduate, our company has grown from strength to strength. Mr. Sharma's vision and expertise have guided us through two decades of excellence, setting the standard for security services across Mumbai, Maharashtra, Gujarat, Uttar Pradesh, and Delhi.",
      image: "https://sp-ao.shortpixel.ai/client/q_lossy,ret_img,w_2016/https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg"
    },
    service: {
      message: "We offer a comprehensive range of security solutions tailored to meet the diverse needs of our clients. From providing highly trained officers to implementing advanced security measures, our services are designed to ensure the utmost safety and peace of mind.",
      image: "https://sp-ao.shortpixel.ai/client/q_lossy,ret_img,w_2016/https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg"
    },
    strength: {
      items: [
        {
          title: "Experience",
          description: "With 20 years in the security industry, we bring a wealth of experience and knowledge to every engagement."
        },
        {
          title: "Clientele",
          description: "Our extensive portfolio includes numerous satisfied clients who rely on our services for their security needs."
        },
        {
          title: "Financial Stability",
          description: "Our strong financial position enables us to invest in the latest technology and training to maintain the highest standards of security."
        },
        {
          title: "Trained Professionals",
          description: "Our team comprises highly trained and skilled officers, ensuring that our clients receive the best protection available."
        }
      ],
      image: "https://sp-ao.shortpixel.ai/client/q_lossy,ret_img,w_2016/https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg"
    },
    presence: {
      message: "Headquartered in Kalyan, we proudly serve clients across key regions including Mumbai, Maharashtra, Gujarat, Uttar Pradesh, and Delhi. Our strategic presence allows us to offer localized security solutions with a national perspective.",
      image: "https://sp-ao.shortpixel.ai/client/q_lossy,ret_img,w_2016/https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg"
    },
    mission: {
      message: "Our mission is to deliver exceptional security solutions that ensure the safety and well-being of our clients. We strive to set the industry standard through our commitment to excellence, innovation, and integrity.",
      image: ""
    },
    vision: {
      message: "Our vision is to be the leading provider of security services across India, known for our cutting-edge technology, highly skilled personnel, and unwavering dedication to client satisfaction. We aim to adapt and grow with the evolving security landscape to continually meet the needs of our clients.",
      image: ""
    },
    founder: {
      name: "Mr. P. S. Sharma",
      message: "Mr. P. S. Sharma, a BHU graduate, is the visionary behind Saviour Intelligent Guard Force Pvt. Ltd. With a background in security management and a passion for excellence, Mr. Sharma has played a pivotal role in shaping our company's success over the past 20 years.",
      vision: "Looking ahead, Mr. Sharma envisions expanding our services to new regions and adopting cutting-edge technology to enhance our security solutions. His commitment is to ensure that Saviour Intelligent Guard Force Pvt. Ltd. remains at the forefront of the security industry, providing innovative and reliable services to meet the evolving needs of our clients.",
      image: "https://thedailyguardian.com/wp-content/uploads/2024/07/Anand-Mahindra.jpg"
    },
  },
  gallery: [
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 1',
      description: 'Description of Image 1',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 2',
      description: 'Description of Image 2',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 3',
      description: 'Description of Image 3',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 1',
      description: 'Description of Image 1',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 2',
      description: 'Description of Image 2',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 3',
      description: 'Description of Image 3',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 1',
      description: 'Description of Image 1',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 2',
      description: 'Description of Image 2',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 3',
      description: 'Description of Image 3',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 1',
      description: 'Description of Image 1',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 2',
      description: 'Description of Image 2',
    },
    {
      image: 'https://www.issinvestigation.com/wp-content/uploads/2023/08/Benefits-of-Hiring-a-Security-Guard.jpg',
      title: 'Image 3',
      description: 'Description of Image 3',
    },

  ]
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompanyName(state, action) {
      state.name = action.payload;
    },
    updateAddress(state, action) {
      state.address = { ...state.address, ...action.payload };
    },
    updateContact(state, action) {
      state.contact = { ...state.contact, ...action.payload };
    },
    addSlide(state, action) {
      state.slides.push(action.payload);
    },
    removeSlide(state, action) {
      state.slides = state.slides.filter((_, index) => index !== action.payload);
    },
    updateSlide(state, action) {
      const { index, slide } = action.payload;
      state.slides[index] = slide;
    },
    addService(state, action) {
      state.services.push(action.payload);
    },
    removeService(state, action) {
      state.services = state.services.filter((_, index) => index !== action.payload);
    },
    updateService(state, action) {
      const { index, service } = action.payload;
      state.services[index] = service;
    },
    addClient(state, action) {
      state.clients.push(action.payload);
    },
    removeClient(state, action) {
      state.clients = state.clients.filter((_, index) => index !== action.payload);
    },
    updateClient(state, action) {
      const { index, client } = action.payload;
      state.clients[index] = client;
    },
    addStaff(state, action) {
      state.staffs.push(action.payload);
    },
    removeStaff(state, action) {
      state.staffs = state.staffs.filter((_, index) => index !== action.payload);
    },
    updateStaff(state, action) {
      const { index, staff } = action.payload;
      state.staffs[index] = staff;
    },
    updateAboutSection(state, action) {
      state.about = { ...state.about, ...action.payload };
    },
    addTestimonial(state, action) {
      state.testimonials.push(action.payload);
    },
    removeTestimonial(state, action) {
      state.testimonials = state.testimonials.filter((_, index) => index !== action.payload);
    },
    updateTestimonial(state, action) {
      const { index, testimonial } = action.payload;
      state.testimonials[index] = testimonial;
    },
  },
});

export const {
  updateCompanyName,
  updateAddress,
  updateContact,
  addSlide,
  removeSlide,
  updateSlide,
  addService,
  removeService,
  updateService,
  addClient,
  removeClient,
  updateClient,
  addStaff,
  removeStaff,
  updateStaff,
  updateAboutSection,
  addTestimonial,
  removeTestimonial,
  updateTestimonial,
} = companySlice.actions;

export default companySlice.reducer;