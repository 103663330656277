import React from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, List, Header, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const customStyles = useSelector((state) => state.customStyles);
  const classes = useStyles({ customStyles });
  const address = useSelector((state) => state.company.address);
  const contact = useSelector((state) => state.company.contact);
  const services = useSelector((state) => state.company.services);

  return (
    <div className={classes.footer}>
      <Grid divided inverted stackable >
        <Grid.Row>
          <Grid.Column style={{ marginLeft: '1em' }} width={3}>
            <Header inverted as='h4' content='About' />
            <List link inverted>
              <List.Item as={NavLink} to="/home">Home</List.Item>
              <List.Item as={NavLink} to="/about-us">About Us</List.Item>
              <List.Item as={NavLink} to="/services">Services</List.Item>
              <List.Item as={NavLink} to="/clients">Clients</List.Item>
              <List.Item as={NavLink} to="/staffs">Staffs</List.Item>
              <List.Item as={NavLink} to="/gallery">Gallery</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Services' />
            <List link inverted>
              {services.map(item => <List.Item as='a' key={item.title}>{item.title}</List.Item>)}
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4' inverted>
              Contact Information
            </Header>
            <p>
              <Icon name='map marker alternate' /> Address: {address.street}, {address.city}, {address.state}, {address.country}, {address.postal_code}
            </p>
            <p>
              <Icon name='phone' /> Phone: {contact.phone_number}
            </p>
            <p>
              <Icon name='mail' /> Email: {contact.email}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={classes.bottomRow}>
          <Grid.Column width={8} className={classes.socialLinks}>
            <Icon name='facebook' link size='large' />
            <Icon name='twitter' link size='large' />
            <Icon name='linkedin' link size='large' />
            <Icon name='instagram' link size='large' />
          </Grid.Column>
          <Grid.Column width={8} className={classes.copyright}>
            <p>&copy; {new Date().getFullYear()} Saviour Intelligent Guard Force Pvt. Ltd. All rights reserved.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Footer;

const useStyles = createUseStyles({
  footer: {
    color: '#FFFFFF',
    padding: '4em 0',
    backgroundImage: (props) => props.customStyles.background,
  },
  bottomRow: {
    marginTop: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'space-around',
    '& > .icon': {
      margin: '0 1.5em',
      color: '#FFFFFF',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
      '&:hover': {
        color: '#1DA1F2', // Example hover color
      }
    }
  },
  copyright: {
    textAlign: 'justify',
    fontSize: '1em',
    color: '#FFFFFF',
    paddingRight: '1em'
  }
});
