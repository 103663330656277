import React from 'react';
import { Container, Header, Grid, Card } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';


const ServicesPage = () => {
  const classes = useStyles();
  const services = useSelector((state) => state.company.services);

  return (
    <div className={classes.page}>
      <Container>
        <Header as='h2' textAlign='center' className={classes.header}>
          Our Services
        </Header>
        <Grid stackable columns={3} centered stretched>
          {services.map((service, index) => (
            <Grid.Column key={index}>
              <Card fluid centered raised className={classes.card}>
                <img
                  src={service.imageUrl}
                  className={classes.image}
                  wrapped
                  ui={false}
                  alt={Card}
                />
                <Card.Content className={classes.cardContent}>
                  <Card.Header className={classes.cardHeader}>
                    {service.title}
                  </Card.Header>
                  <Card.Description className={classes.cardDescription}>
                    {service.description}
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default ServicesPage;

const useStyles = createUseStyles({
  page: {
    padding: '4em 0em',
    background: '#f9f9f9',
  },
  header: {
    marginBottom: '3em',
    fontSize: '2.8em',
    color: '#333',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '700',
  },
  card: {
    borderRadius: '15px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    minHeight: '450px', // Increased to allow more room for content
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Ensure content starts from the top
    overflow: 'hidden',
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    height: '200px', // Consistent image height
    width: '100%', // Ensure image takes up full width
    objectFit: 'cover',
    flexShrink: 0, // Prevent image from shrinking
  },
  cardContent: {
    padding: '1.5em',
    textAlign: 'center',
    flex: '1', // Allow content to take up remaining space
  },
  cardHeader: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: '#333',
    fontFamily: 'Roboto, sans-serif',
  },
  cardDescription: {
    marginTop: '1em',
    fontSize: '1.1em',
    color: '#555',
    fontFamily: 'Roboto, sans-serif',
  },
});

