import React, { useState } from 'react';
import { Segment, Container, Header, Grid, Image, Card, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';



const GalleryPage = () => {
  const classes = useStyles();
  const gallery = useSelector((state) => state.company.gallery);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Segment vertical className={classes.page}>
      <Container>
        <Header as='h2' textAlign='center' className={classes.header}>
          Gallery
        </Header>
        <Grid stackable columns={3}>
          {gallery.map((image, index) => (
            <Grid.Column key={index} mobile={16} tablet={8} computer={5}>
              <Card fluid centered raised className={classes.card}>
                <Image
                  src={image.image}
                  wrapped
                  ui={false}
                  className={classes.image}
                  onClick={() => handleOpen(image.image)}
                />
                <Card.Content className={classes.content}>
                  <Card.Header className={classes.cardHeader}>
                    {image.title}
                  </Card.Header>
                  <Card.Description className={classes.cardDescription}>
                    {image.description}
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      </Container>

      {selectedImage && (
        <Modal open={open} onClose={handleClose} basic size='large'>
          <Modal.Content>
            <Image src={selectedImage} className={classes.modalImage} centered />
          </Modal.Content>
        </Modal>
      )}
    </Segment>
  );
};

export default GalleryPage;

const useStyles = createUseStyles({
  page: {
    padding: '5em 0em',
    background: '#f9f9f9',
  },
  header: {
    marginBottom: '4em',
    fontSize: '3em',
    fontWeight: 700,
    color: '#333',
    fontFamily: 'Roboto, sans-serif',
  },
  card: {
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    borderRadius: '10px 10px 0 0',
    cursor: 'pointer',
  },
  content: {
    padding: '1em',
    textAlign: 'center',
  },
  cardHeader: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '0.5em',
  },
  cardDescription: {
    fontSize: '1em',
    color: '#555',
  },
  modalImage: {
    maxHeight: '80vh',
    maxWidth: '100%',
  },
});
