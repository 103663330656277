import React from 'react';
import { Container, Icon, Grid } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const Services = ({ service, index }) => {
  const customStyles = useSelector((state) => state.customStyles);
  const classes = useStyles({ customStyles, image: service.imageUrl });

  return (
    <Grid.Column key={index}>
      <Container textAlign='center' className={classes.container}>
        <Icon name={service.icon} size='huge' className={classes.icon} />
        <h3 className={classes.text}>{service.title}</h3>
        <p className={classes.text}>{service.description}</p>
      </Container>
    </Grid.Column>
  );
};

export default Services;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
    width: '300px',
    borderRadius: '10px',
    padding: '2em',
    marginBottom: '25px',
    backgroundImage: props => `url(${props.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    '&:hover': {
      animation: '$scaleUp 0.4s forwards',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', 
      borderRadius: '10px',
      zIndex: 1,
    },
  },
  icon: {
    color: 'yellow', 
    zIndex: 1,
  },
  text: {
    color: '#FFFFFF',
    position: 'relative',
    zIndex: 2,
  },
  '@keyframes scaleUp': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    '100%': {
      transform: 'scale(1.05)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
});
