import { configureStore } from '@reduxjs/toolkit';
import styleSlice from './slices/styleSlice';
import companySlice from './slices/companySlice';

const store = configureStore({
  reducer: {
    customStyles: styleSlice,
    company: companySlice
  },
});

export default store;