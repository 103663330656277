import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const Staff = ({ name, role, photo, description }) => {
  const customStyles = useSelector((state) => state.customStyles);
  const classes = useStyles({ customStyles });

  return (
    <div className={classes.container}>
      <Image src={photo} ui={false} className={classes.image} />
      <div className={classes.content}>
        <Card.Header className={classes.name}>{name}</Card.Header>
        <Card.Meta className={classes.role}>{role}</Card.Meta>
      </div>
    </div>
  );
};

export default Staff;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    height: '620px',
    width: '320px !important',
    padding: '0',
    marginTop: '30px',    // Top margin
    marginBottom: '60px', // Bottom margin
    marginLeft: '20px',   // Left margin
    marginRight: '20px',  // Right margin
    borderWidth: '2px',
    borderColor: '#ddd',
    backgroundImage: props => props.customStyles.background,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    boxSizing: 'border-box',
  },
  image: {
    width: '320px',
    height: '60%',
    objectFit: 'cover',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: '0.8',
    },
  },
  name: {
    fontSize: '20px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '5px 0',
  },
  role: {
    fontSize: '16px',
    color: '#CCCCCC',
    textAlign: 'center',
  },
});
